import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN: string =
  process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

const isDevelopment = process.env.SS_ENV === 'development'
const isSentryEnabled = process.env.SENTRY_DEV_ENABLED === 'true'

Sentry.init({
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  dsn: SENTRY_DSN,
  environment: process.env.SS_ENV,
  enabled: !isDevelopment || isSentryEnabled,
  attachStacktrace: true,
  ignoreErrors: [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    'Non-Error promise rejection captured',
  ],
})