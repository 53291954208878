import { Box, Button, createStyles, DialogContent, DialogTitle, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'
import { inject, observer } from 'mobx-react'
import React from 'react'
import RootStore from '../../src/stores/RootStore'
import { FormTextField } from '../shared'

interface Props {
  classes?: Record<string, string>
  code: string
  onChange: (key: string, value: string) => void
  resendCode: () => void
  logout: () => void
  mobile?: string
  store?: RootStore
}

const styles = () => createStyles({
  verificationInput: {
    '& input': {
      textAlign: 'center',
      fontSize: 20,
      letterSpacing: 8,
      fontWeight: 'bold',
    },
  },
})

const VerificationStage = (props: Props) => {
  const { authStore: { isLoggedIn } } = props.store!
  const { classes, code, onChange, resendCode, logout, mobile } = props
  return <>
    <DialogTitle>2-Factor Authentication</DialogTitle>

    <DialogContent>
      <Box display='flex' flexDirection='column' style={{ alignItems: 'center' }}>
        <Typography style={{ marginBottom: 20 }}>
          Please enter the 6 digit verification code sent to {mobile ?? ''}
        </Typography>

        <Box className={classes?.verificationInput}>
          <FormTextField
            property='code'
            value={code}
            onChange={onChange}
            type='tel'
          />
        </Box>

        <Typography>Didn't receive a code?</Typography>

        <Button variant='text' color='primary' onClick={resendCode} fullWidth>Resend code</Button>
        {isLoggedIn && <Button variant='text' color='primary' onClick={logout} fullWidth>Logout</Button>}
      </Box>
    </DialogContent>
  </>
}

export default withStyles(styles)(inject('store')((observer(VerificationStage))))