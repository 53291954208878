import BaseErrorHandler from '../shared-lib/communication/BaseErrorHandler'
import { FormattedError } from '../shared-lib/communication/ErrorHelper'

export default class ErrorHandler extends BaseErrorHandler {
  get location() {
    return window.location.href
  }

  get userId() {
    return this.store.authStore.user._id
  }

  showErrorInUI = (error: FormattedError) => {
    this.store.uiStore.showToast(error.message, 'error')
  }

  handleExpiredSession = () => {
    this.store.uiStore.showLoginModal('login')
  }

  trackInAnalytics = () => {
    // Not tracking in analytics in this project
  }

  expiredCodes = ['AUTH_TOKEN_EXPIRED', 'AUTH_HEADER_ERROR']
}