import { action, observable } from 'mobx'
import ErrorHandler from '../../lib/ErrorHandler'
import Communication from '../../shared-lib/communication/Communication'
import getApiUrl from '../../shared-lib/communication/getApiUrl'
import AuthStore from './AuthStore'
import UiStore from './UiStore'

export type $RootStore = RootStore

export default class RootStore {
  communication!: Communication
  authStore: AuthStore
  uiStore: UiStore
  remoteConfig: any
  errorHandler: ErrorHandler
  @observable ready = false
  config: any = {}

  constructor() {
    this.config.name = process.env.SS_ENV
    this.authStore = new AuthStore(this)
    this.uiStore = new UiStore()
    this.errorHandler = new ErrorHandler(this)
  }

  // Fetch local storage and autologin if possible
  loadStore = async () => {
    const { authStore, uiStore } = this
    this.ready = false

    await this.getRemoteSettings()

    const tokens = await this.authStore.fetchFromStorage()

    if (!!tokens.user && !!tokens.authToken) {
      const expired = new Date(tokens.authToken.expires) < new Date()
      let blocked = false

      if (!expired) {
        const { checkBlockedUser } = await authStore.checkBlockedUser()
        blocked = checkBlockedUser
      }

      if (blocked || expired) {
        authStore.logOut()
      }
    }

    if (!authStore.isLoggedIn) {
      uiStore.showLoginModal('login')
    }

    this.errorHandler.setUserContext(this.authStore.user)

    this.ready = true
  }

  @action.bound loadCommunication = async (config: any) => {
    // Get API url and load config
    try {
      const { apiHost } = await getApiUrl({
        apiDefault: config.apiDefault,
        platform: config.platform,
        appVersion: config.appVersion,
        useSecure: process.env.SS_ENV === 'development' ? '' : 's',
        override: config.override,
      })

      config.apiHost = apiHost
    } catch (e) {
      return false
    }

    this.communication = new Communication(this, {
      apiHost: config.apiHost,
      platform: config.platform,
      env: config.env,
      timezone: config.timezone,
      addNetworkLatency: 0,
    })

    return true
  }

  // Get remote settings
  getRemoteSettings = async (): Promise<boolean> => {
    try {
      const response = await this.communication.requester.settings()

      if (!response.settings) return false

      this.remoteConfig = response.settings.settings
      return true
    } catch (e) {
      return false
    }
  }
}
