import { createTheme } from '@material-ui/core/styles'
import { Overrides as CoreOverrides } from '@material-ui/core/styles/overrides'
import { AlertClassKey, AutocompleteClassKey } from '@material-ui/lab'
import { CSSProperties } from '@material-ui/styles'

import { colors, fonts } from '../theme'

// Special overrides
interface Overrides extends CoreOverrides {
  MuiAlert: Partial<Record<AlertClassKey, CSSProperties | (() => CSSProperties)>> | undefined
  MuiAutocomplete: Partial<Record<AutocompleteClassKey, CSSProperties | (() => CSSProperties)>> | undefined
}

const spacing = 8

const specialOverrides: Overrides = {
  MuiAlert: {
    root: {
      borderRadius: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',

      fontSize: '1rem',
      fontWeight: 200,
      lineHeight: 1.5,
      color: colors.INK900,
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      padding: 0,
      paddingLeft: spacing * 2,
      paddingRight: spacing * 2,
      width: '100%',
    },
    option: {
      padding: 0,
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: colors.BG_PURPLE,
      },
      '&[aria-selected="true"]': {
        backgroundColor: colors.BG_PURPLE,
      },
      '&[data-focus="true"]': {
        backgroundColor: colors.BG_PURPLE,
      },
    },
    paper: {
      borderRadius: spacing,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: 0,
      margin: 0,
      marginTop: -5,
      maxHeight: '30vh',
      overflowY: 'scroll',
      boxShadow: `${colors.FIELD_SHADOW} 0px 3px 8px`,
    },
  },
}

const themer = createTheme({
  typography: {
    fontFamily: fonts.muli,
    h1: {
      fontFamily: fonts.quiche,
      color: colors.INK900,
      fontWeight: 500,
    },
    h2: {
      fontFamily: fonts.quiche,
      color: colors.INK900,
      fontWeight: 500,
    },
    h3: {
      fontFamily: fonts.quiche,
      color: colors.INK900,
      fontWeight: 500,
      fontSize: '2.8rem',
    },
    h4: {
      fontFamily: fonts.quiche,
      color: colors.INK900,
      fontSize: '1.6rem',
      lineHeight: 1.24,
      fontWeight: 500,
    },
    h5: {
      fontFamily: fonts.quiche,
      color: colors.INK900,
      fontSize: '1.25rem',
      lineHeight: 1,
      fontWeight: 400,
    },
    h6: {
      fontFamily: fonts.muli,
      color: colors.INK900,
      fontSize: '1.1rem',
      lineHeight: 1,
      fontWeight: 400,
    },
    subtitle1: {
      fontWeight: 200,
      fontSize: '1.1rem',
      color: colors.INK900,
    },
    subtitle2: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: colors.INK900,
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 200,
      lineHeight: 1.5,
      color: colors.INK900,
    },
    body2: {
      fontWeight: 'bold',
      fontSize: '1rem',
      lineHeight: 1.5,
      color: colors.INK900,
    },
    caption: {
      fontSize: '0.9rem',
      fontWeight: 200,
      lineHeight: 1.35,
      color: colors.INK500,
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: 800,
      letterSpacing: 0.5,
      color: colors.INK400,
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        '& input': {
          fontSize: 14,
        },
        '& .MuiSelect-root': {
          fontSize: 14,
        },
        '& .MuiInputAdornment-root .MuiTypography-body1': {
          fontSize: 14,
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        borderRadius: 22.5,
        height: 45,
        backgroundColor: colors.PRI900,
        color: colors.BG100,
        fontFamily: fonts.muli,
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.5,
        textTransform: 'none',
        paddingLeft: spacing * 3,
        paddingRight: spacing * 3,
        paddingTop: spacing / 2,
        '&:hover': {
          backgroundColor: colors.PRI900,
          color: colors.BG100,
        },
      },
      sizeSmall: {
        height: 30,
      },
      containedSecondary: {
        borderRadius: 22.5,
        height: 45,
        backgroundColor: colors.BG100,
        color: colors.PRI900,
        fontFamily: fonts.muli,
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.5,
        textTransform: 'none',
        paddingLeft: spacing * 3,
        paddingRight: spacing * 3,
        paddingTop: spacing / 2,
        '&:hover': {
          backgroundColor: colors.BG200,
          color: colors.PRI900,
        },
      },
      textPrimary: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: colors.PRI900,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
      },
      textSecondary: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: colors.BLUE900,
        textTransform: 'none',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          textDecoration: 'underline',
          '@media (hover: none)': {
            backgroundColor: 'transparent',
            textDecoration: 'underline',
          },
        },
      },
      outlinedPrimary: {
        borderRadius: 22.5,
        height: 45,
        borderColor: colors.PRI900,
        color: colors.PRI900,
        fontFamily: fonts.muli,
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.5,
        textTransform: 'none',
        paddingLeft: spacing * 3,
        paddingRight: spacing * 3,
        paddingTop: spacing / 2,
        '&:hover': {
          borderColor: colors.PRI900,
          opacity: 0.7,
        },
      },
      outlinedSecondary: {
        borderRadius: 22.5,
        height: 45,
        borderColor: colors.INK400,
        color: colors.PRI900,
        fontFamily: fonts.muli,
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: 1.5,
        textTransform: 'none',
        paddingLeft: spacing * 3,
        paddingRight: spacing * 3,
        paddingTop: spacing / 2,
        '&:hover': {
          borderColor: colors.PRI900,
          opacity: 0.7,
        },
      },
    },
    MuiTextField: {
      root: {
        marginTop: 0,
      },
    },
    MuiInput: {
      root: {
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        'input[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },
    },
    MuiInputBase: {
      root: {
        minHeight: 48,
        boxShadow: `${colors.FIELD_SHADOW} 0px 3px 8px`,
        backgroundColor: colors.BG100,
        color: colors.INK900,
        padding: spacing * 1,
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderRadius: 10,
        marginTop: 0,
        '&$disabled': {
          boxShadow: 'none',
          backgroundColor: colors.BG350,
        },
      },
      multiline: {
        minHeight: 48,
        boxShadow: `${colors.FIELD_SHADOW} 0px 3px 8px`,
        backgroundColor: colors.BG100,
        color: colors.INK900,
        padding: spacing * 1,
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderRadius: 10,
        marginTop: 0,
        '&$disabled': {
          boxShadow: 'none',
          backgroundColor: colors.BG350,
        },
      },
    },
    MuiPaper: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
      },
      rounded: {
        borderRadius: spacing,
      },
      elevation2: {
        boxShadow: `${colors.PAPER_SHADOW} 0px 6px 16px`,
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '0px solid transparent',
        boxShadow: `${colors.CARD_SHADOW} 0px 6px 16px`,
      },
    },
    MuiMenuItem: {
      root: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    },
    MuiListItem: {
      root: {
        height: 45,
        paddingLeft: 20,
        paddingRight: 60,
        marginBottom: spacing,
        '&$selected': {
          backgroundColor: colors.BG_PURPLE,
          '&:hover': {
            backgroundColor: colors.BG_PURPLE,
          },
          '&:focus': {
            color: colors.BG_PURPLE,
          },
        },
      },
      button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          backgroundColor: colors.BG_PURPLE,
        },
        '&:focus': {
          color: colors.BG_PURPLE,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 35,
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: fonts.quiche,
        color: colors.INK900,
        fontSize: '1.1rem',
        lineHeight: 1,
        fontWeight: 300,
        paddingTop: 2,
      },
      secondary: {
        fontSize: '1rem',
        fontWeight: 200,
        lineHeight: 1.5,
        color: colors.INK900,
      },
    },
    MuiSelect: {
      selectMenu: {
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: colors.PRI900,
      },
    },
    MuiChip: {
      root: {
        height: 20,
        borderRadius: 10,
        fontWeight: 'bold',
        fontSize: '0.7rem',
        lineHeight: 1.5,
        color: colors.BG100,
        backgroundColor: colors.BLUE100,
      },
    },
    MuiSwitch: {
      switchBase: {
        color: colors.BLUE900,
      },
      thumb: {
        color: colors.BLUE900,
      },
      track: {
        backgroundColor: colors.BG900,
        '$checked$checked + &': {
          opacity: 0.8,
          backgroundColor: colors.BLUE900,
        },
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: colors.BLUE900,
        '&:disabled': {
          color: colors.BG400,
        },
      },
      colorSecondary: {
        color: colors.PRI900,
      },
    },
    MuiCard: {
      root: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        boxShadow: `${colors.PAPER_SHADOW} 0px 6px 16px`,
        borderRadius: spacing,
        padding: 12,
        marginTop: 12,
      },
    },
    MuiExpansionPanel: {
      rounded: {
        boxShadow: `${colors.PAPER_SHADOW} 0px 6px 16px`,
        borderRadius: spacing,
        overflow: 'hidden',
        '&$first-child': {
          boxShadow: `${colors.PAPER_SHADOW} 0px 6px 16px`,
          borderRadius: spacing,
          overflow: 'hidden',
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        width: 13,
        height: 13,
        color: colors.BLUE100,
        '&$checked': {
          color: colors.BLUE900,
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        marginLeft: spacing,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: spacing * 4,
      },
    },
    MuiDialogContent: {
      root: {
        padding: spacing * 4,
        backgroundColor: colors.BG300,
      },
    },
    MuiDialogActions: {
      root: {
        padding: spacing * 4,
      },
    },
    MuiAvatar: {
      root: {
        width: spacing * 7,
        height: spacing * 7,
      },
      colorDefault: {
        backgroundColor: colors.AVATAR300,
        color: colors.PRI900,
        fontFamily: fonts.quiche,
        fontSize: '2rem',
        lineHeight: 1,
        fontWeight: 400,
      },
    },
    MuiTabs: {
      root: {
        display: 'flex',
        alignItems: 'center',
      },
      indicator: {
        backgroundColor: colors.BLUE900,
      },
      scrollButtons: {
        color: colors.BLUE900,
      },
    },
    MuiTab: {
      root: {
        height: 60,
      },
      textColorPrimary: {
        fontWeight: 'bold',
        fontSize: '1rem',
        lineHeight: 1.5,
        color: colors.INK400,
        textTransform: 'none',
        '&$selected': {
          color: colors.BLUE900,
        },
      },
    },
    ...specialOverrides,
  },
})

export default themer
