import { ApolloLink, split } from '@apollo/client/core'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { createUploadLink } from 'apollo-upload-client'

import { $RootStore } from '../../src/stores/RootStore'
import { $CommunicationConfig } from './Communication'

const fetch = require('isomorphic-fetch')
const WebSocket = require('isomorphic-ws')

const fileUploadResolvers = ['uploadFile', 'uploadSignature', 'uploadConsultationImages']

const createClientLinks = (store: $RootStore, config: $CommunicationConfig) => {
  const uploadLink: ApolloLink = createUploadLink({
    uri: config.apiHost,
    fetch,
  }) as any

  const authLink = setContext(({ operationName }, { headers }) => {
    if (!operationName) return

    const auth = store.authStore?.getAccessAuthToken(operationName)?.auth

    return {
      headers: {
        ...headers,
        authorization: auth ? `Bearer ${auth}` : '',
        timezone: config.timezone,
        platform: config.platform,
      },
    }
  })

  // Retry failed requests
  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        const errorCode = error?.result?.errors[0]?.extensions?.code

        // Don't retry if auth token has expired
        if (errorCode === 'AUTH_TOKEN_EXPIRED') return false

        return !!error
      },
    },
  })

  const cleanTypeName = new ApolloLink((operation, forward) => {
    if (operation.variables && !fileUploadResolvers.includes(operation.operationName)) {
      const omitTypename = (key: string | undefined, value: any) => (key === '__typename' ? undefined : value)
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }
    return forward(operation).map(data => {
      return data
    })
  })

  if (config.wsHost) {
    const wsLink = new WebSocketLink({
      uri: config.wsHost,
      options: {
        reconnect: true,
        lazy: true,
        connectionParams: () => {
          const auth = store.authStore?.authToken?.auth

          return {
            headers: {
              authorization: auth ? `Bearer ${auth}` : '',
            },
          }
        },
      },
      webSocketImpl: WebSocket,
    })

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      wsLink,
      uploadLink as any,
    )

    return {
      links: [
        cleanTypeName,
        retryLink,
        authLink,
        splitLink,
      ],
      wsLink,
    }
  }

  return {
    links: [
      cleanTypeName,
      retryLink,
      authLink,
      uploadLink,
    ],
  }
}

export default createClientLinks
