import { Dialog } from '@material-ui/core'
import { action, computed, observable } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import { useViewModelWithProps } from '../../hooks/useViewModel'
import RootStore from '../../src/stores/RootStore'
import LoginStage from '../loginModal/LoginStage'
import VerificationStage from '../loginModal/VerificationStage'

interface Props {
  store: RootStore
}

class ViewModel {
  store?: RootStore
  @observable code = ''
  @observable credentials: any = {
    emailAddress: '',
    password: '',
  }

  constructor(props: { store?: RootStore }) {
    this.store = props.store

    this.credentials.emailAddress = this.store?.authStore.user.emailAddress ?? ''
  }

  componentDidMount() {
    const { authStore } = this.store!
    this.credentials.password = ''
    this.code = ''
    this.credentials.emailAddress = authStore.user.emailAddress ?? ''
  }

  @action.bound onFieldChange = (_property: string, value: string) => {
    const { submitVerification } = this

    if (this.code !== value && value.length <= 6) {
      this.code = value
      if (value.length === 6) {
        submitVerification()
      }
    }
  }

  @action.bound submitVerification = async () => {
    const { authStore } = this.store!
    let success = false

    success = await authStore.verifyMobileAndSignIn_api(authStore.user.mobile ?? '', this.code)
    if (success) {
      this.postLoginActions()
    } else {
      this.code = ''
    }
  }

  @action.bound resendCode = async () => {
    const { uiStore, authStore } = this.store!
    const timeout = 15000
    const timeSinceSending = Date.now() - authStore.codeSentAt

    // Throttle click events so we don't spam the service
    if (timeSinceSending < timeout) {
      const secondsToWait = Math.ceil((timeout - timeSinceSending) / 1000)
      uiStore.showToast(
        `Please wait... A confirmation code was sent recently, use that one or wait ${secondsToWait.toString()} seconds.`,
      )

      return
    }
    const resent = await authStore.sendMobileVerification_api(authStore.user.mobile ?? '')
    if (resent) {
      uiStore.showToast('We have resent the verification code to the mobile number provided.')
    }
  }

  @action.bound logout() {
    const { authStore } = this.store!

    authStore.logOut()
  }

  @action.bound postLoginActions() {
    const { uiStore } = this.store!
    this.code = ''
    uiStore.hideLoginModal()
  }

  @computed get open() {
    const { uiStore } = this.store!

    return uiStore.loginModalVisible
  }

  @computed get stage() {
    const { uiStore } = this.store!

    return uiStore.loginModalStage
  }

  @computed get mobile() {
    const { authStore } = this.store!

    return authStore.user.mobile ?? ''
  }

  @action.bound onEditField(key: string, value: string) {
    this.credentials[key] = value
  }

  @action.bound async proceed() {
    if (!this.validateFields()) return false
    const { authStore, uiStore } = this.store!
    const { emailAddress, password } = this.credentials
    authStore.authToken = undefined
    const success = await authStore.login(emailAddress, password)
    if (!success) return
    if (!this.mobile || this.mobile === '') {
      uiStore.showToast('No mobile number associated with this user', 'error')
      return
    } else {
      await authStore.sendMobileVerification_api(this.mobile)
      uiStore.loginModalStage = '2FA'
    }
  }

  validateFields = (): boolean => {
    if (this.credentials.emailAddress.length === 0 || this.credentials.password.length === 0) return false
    return true
  }
}

const LoginModal = (props: Props) => {
  const viewModel = useViewModelWithProps(ViewModel, { store: props.store! })
  const {
    open,
    onEditField,
    proceed,
    logout,
    credentials,
    onFieldChange,
    resendCode,
    mobile,
    stage,
  } = viewModel

  return <>
    <Dialog
      open={open}
      maxWidth='xs'
      fullWidth
    >
      {stage === 'login' ?
        <>
          <LoginStage
            onEditField={onEditField}
            proceed={proceed}
            logout={logout}
            credentials={credentials}
          />
        </> :
        <VerificationStage
          onChange={onFieldChange}
          logout={logout}
          resendCode={resendCode}
          code={credentials.code}
          mobile={mobile}
        />
      }
    </Dialog>
  </>
}

export default observer(LoginModal)