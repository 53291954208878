import { action, observable } from 'mobx'

interface $DialogSettings {
  title: string
  body: string
  inputProps?: any
  buttons?: Array<{
    title: string
    onClick?: (value?: any) => void
  }>
}

class UiStore {
  @observable toastData = {
    message: '',
    open: false,
    type: 'message',
  }
  @observable loginModalVisible = false
  @observable loginModalStage: 'login' | '2FA' = 'login'

  toastTimer: any
  @observable dialog: $DialogSettings | Record<string, any> = {}

  @action.bound showLoginModal = (stage: 'login' | '2FA' = 'login') => {
    if (stage) this.loginModalStage = stage
    this.loginModalVisible = true
  }

  @action.bound hideLoginModal = () => {
    this.loginModalVisible = false
  }

  // Show the toast with message and optional type
  @action.bound showToast = (message: string, type?: string) => {
    this.toastData.open = true
    this.toastData.message = message
    this.toastData.type = type || 'message'

    if (this.toastTimer) clearTimeout(this.toastTimer)

    this.toastTimer = setTimeout(this.hideToast, 4000)
  }

  // Hide the toast message
  @action.bound hideToast = () => {
    clearTimeout(this.toastTimer)
    this.toastTimer = null
    this.toastData.open = false
  }

  // Show Dialog
  @action.bound showMessage(dialog: $DialogSettings | Record<string, any>): void {
    this.dialog = dialog
  }
}

export default UiStore
