import { action, computed, observable } from 'mobx'

import { $User } from '../../types'
import { $RootStore } from './RootStore'

class AuthStore {
  store: $RootStore
  @observable user: $User = {}
  @observable authToken: Pick<AuthToken, 'role' | 'auth'> | undefined | null = null
  tempAuthToken: Pick<AuthToken, 'role' | 'auth'> | undefined | null = null
  @observable loading = false
  codeSentAt: number
  sessionExpired = false

  constructor(store: $RootStore) {
    this.store = store
    this.codeSentAt = 0
  }

  // Fetch local storage for authToken
  fetchFromStorage = async (): Promise<any> => {
    try {
      const authToken = localStorage.getItem('authToken')
      const user = localStorage.getItem('user')
      if (authToken) this.authToken = JSON.parse(authToken)
      if (user) this.user = JSON.parse(user)
      return {
        authToken: this.authToken,
        user: this.user,
      }
    } catch (e) {
      return {
        authToken: {},
        user: {},
      }
    }
  }

  checkBlockedUser = async () => {
    try {
      return await this.store.communication.requester.checkBlockedUser()
    } catch {
      return { checkBlockedUser: true }
    }
  }

  // Sync data in local storage
  syncInStorage = (key: string, data: any): void => {
    localStorage.setItem(key, JSON.stringify(data))
  }

  verifyMobileAndSignIn_api = async (mobile: string, code: string) => {
    try {
      const response = await this.store.communication.requester.verifyMobileAndSignIn({ mobile, code })
      const data = response?.verifyMobileAndSignIn
      if (data?.authToken && data.user) {
        const { authToken, user } = data
        this.authToken = authToken as Pick<AuthToken, 'role' | 'auth'>

        // Set user data
        this.user = user as $User

        // Sync in storage
        this.syncInStorage('authToken', authToken)
        this.syncInStorage('user', user)
      }
      return !!data?.authToken
    } catch {
      return false
    }
  }

  @action.bound async sendMobileVerification_api(mobile: string): Promise<boolean> {
    this.loading = true
    const response = await this.store.communication.requester.sendMobileVerification({ mobile, silent: false })
    this.loading = false
    this.codeSentAt = Date.now()
    const authToken = response?.sendMobileVerification?.authToken
    // Return false if no authToken
    if (!authToken) {
      return false
    }

    this.tempAuthToken = authToken as Pick<AuthToken, 'role' | 'auth'>

    return !!this.tempAuthToken

  }

  // Login request
  @action.bound login = async (emailAddress: string, password: string): Promise<boolean> => {
    const { errorHandler } = this.store
    try {
      // Login with password
      const response = await this.store.communication.requester.loginWithEmailAndPassword({ emailAddress, password })

      if (!response.loginWithEmailAndPassword) return false

      const { user, authToken } = response.loginWithEmailAndPassword

      // Save retreived authToken and user
      this.authToken = authToken as Pick<AuthToken, 'role' | 'auth'>
      this.user = user as $User

      // Sync in storage
      this.syncInStorage('authToken', authToken)
      this.syncInStorage('user', user)

      errorHandler.setUserContext(this.user)
      return true
    } catch (e) {
      return false
    }
  }

  // If we're in the auth flow we want to use the tempAuthToken
  getAccessAuthToken(_operationName?: string) {
    return this.authToken
  }

  @computed get isLoggedIn(): boolean {
    if (this.authToken && this.authToken.role === 'ADMIN') {
      return true
    }

    return false
  }

  // Manually expire the JWT token, for testing
  expireToken = () => {
    this.authToken.auth = 'LOCALLY_EXPIRED_TOKEN'
    this.syncInStorage('authToken', this.authToken)
  }

  // Log out user
  logOut = () => {
    localStorage.clear()
    this.authToken = null
  }
}

export default AuthStore
