import React, { Component } from 'react'
import { Box } from '@material-ui/core'

class ResponsiveDrawerFooter extends Component {
  render() {
    return (
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
        <Box display={'flex'} justifyContent={'center'} width={'240px'} mt={5} mb={3}>
          <img src={'/static/logo/secret-spa-logo.svg'} alt='' style={{ width: '70%', height: 'auto' }} />
        </Box>
      </Box>
    )
  }
}

export default ResponsiveDrawerFooter
