import 'react-markdown-editor-lite/lib/index.css'

import { Provider } from 'mobx-react'
import App from 'next/app'
import Router from 'next/router'
import NProgress from 'nprogress'
import React from 'react'
import Head from 'next/head'

import { ThemeProvider } from '@material-ui/styles'

import { ResponsiveDrawer } from '../components/drawer'
import MainLayout from '../components/layouts/MainLayout'
import { GenericPopup, Toast } from '../components/shared'
import RootStore from '../src/stores/RootStore'
import themer from '../theme/themer'
import LoginModal from '../components/shared/LoginModal'
import packageJSON from '../package.json'
import { getBrowserTimezone } from '../shared-lib/utils'

class MyApp extends App {
  // Create root store
  store = new RootStore()
  drawer: any
  loaded = false

  componentDidMount = async () => {
    this.loaded = false

    await this.loadStore()

    // Bind to Router events
    Router.events.on('routeChangeStart', _url => {
      NProgress.start()
    })
    Router.events.on('routeChangeComplete', () => NProgress.done())
    Router.events.on('routeChangeError', () => NProgress.done())

    this.loaded = true
  }

  loadStore = async () => {
    const communicationLoaded = await this.store.loadCommunication({
      apiDefault: process.env.API_DEFAULT,
      platform: process.env.PLATFORM,
      timezone: getBrowserTimezone(),
      env: process.env.SS_ENV,
      appVersion: packageJSON.version,
      override: process.env.API_OVERRIDE_URL,
    })

    if (!communicationLoaded) {
      this.store.uiStore.showMessage({
        title: 'No internet connection',
        body: 'Please check you are connected to wifi or mobile internet and try again.',
        buttons: [
          {
            title: 'Try again',
            onPress: () => {
              // Delay to ensure popup is hidden before re-triggering
              setTimeout(() => {
                this.loadStore()
              }, 1000)
            },
          },
        ],
      })

      return
    }

    await this.store.loadStore()
  }

  render() {
    const { Component, pageProps } = this.props
    const { uiStore } = this.store

    return (
      <>
        <Head>
          <meta charSet='utf-8' />
          <meta
            name='viewport'
            content='minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no'
          />
        </Head>
        <Provider store={this.store}>
          <ThemeProvider theme={themer}>
            <MainLayout>
              <ResponsiveDrawer ref={ref => { this.drawer = ref }} index={1} />
              <Component {...pageProps} />
            </MainLayout>
            <LoginModal store={this.store} />
            <GenericPopup uiStore={uiStore} />
            <Toast toastData={uiStore.toastData} hideToast={uiStore.hideToast} />
          </ThemeProvider>
        </Provider>
      </>
    )
  }
}

export default MyApp
