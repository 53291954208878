import { ApolloClient, FetchPolicy, QueryOptions } from '@apollo/client/core'
import { DocumentNode } from 'graphql'
import { $QueryOptions } from '..'

import { getSdk, Requester } from '../../graphQL/index'
import { $RootStore } from '../../src/stores/RootStore'
import { sleep } from '../utils'

export type ApolloRequesterOptions<V> = Omit<QueryOptions<V>, 'variables' | 'query'>
const validDocDefOps = ['mutation', 'query', 'subscription']
const allowedQueriesWithExpiredToken = [
  'createGuestUser',
  'isApiUp',
  'identifyWithMobile',
  'sendMobileVerification',
  'verifyMobileAndSignIn',
  'verifyMobileAndCompleteAccount',
  'createNewUserWithMobile',
  'page',
  'getConfig',
  'settings',
]

interface ApolloRequesterExtras {
  dev: boolean,
  manageError: (e: any, queryName?: string, queryVariables?: Record<string, any>) => void,
  rootStore: $RootStore,
  addNetworkLatency: number
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function getSdkApollo<C>(
  client: ApolloClient<C>,
  { dev, manageError, rootStore, addNetworkLatency }: ApolloRequesterExtras,
) {
  const requester: Requester = async <R, V>(
    doc: DocumentNode,
    variables: V,
    options?: ApolloRequesterOptions<V>,
  ): Promise<R> => {
    const { authStore: { sessionExpired } } = rootStore

    // Valid document should contain *single* query or mutation unless it's has a fragment
    if (
      doc.definitions.filter(
        d =>
          d.kind === 'OperationDefinition' &&
          validDocDefOps.includes(d.operation),
      ).length !== 1
    ) {
      throw new Error(
        'DocumentNode passed to Apollo Client must contain single query or mutation',
      )
    }

    const definition = doc.definitions[0]

    // Valid document should contain *OperationDefinition*
    if (!definition || definition.kind !== 'OperationDefinition') {
      throw new Error(
        'DocumentNode passed to Apollo Client must contain single query or mutation',
      )
    }

    if (dev) {
      console.info('REQUEST', definition, variables)

      if (addNetworkLatency) {
        await sleep(addNetworkLatency)
      }
    }

    if (sessionExpired && definition && definition.name && !allowedQueriesWithExpiredToken.includes(definition.name.value)) {
      if (dev) {
        console.info('query cancelled due to session expiration:', definition.name.value)
      }
      return {} as R
    }

    switch (definition.operation) {
      case 'mutation': {

        let response

        try {
          response = await client.mutate<R, V>({
            mutation: doc,
            variables,
          })
        } catch (e) {
          manageError(e, definition.name?.value, variables)
          return {} as R
        }

        if (!response || response.data === undefined || response.data === null) {
          throw new Error('No data presented in the GraphQL response')
        }

        if (dev) { console.info('RESPONSE:', response.data) }

        return response.data
      }
      case 'query': {

        let fetchPolicy: FetchPolicy = 'network-only'

        if (options && (options as $QueryOptions).useCache) {
          fetchPolicy = 'cache-first'
          delete (options as $QueryOptions).useCache
        }

        let response

        try {
          response = await client.query<R, V>({
            query: doc,
            variables,
            fetchPolicy,
            ...options,
          })
        } catch (e) {
          manageError(e, definition.name?.value, variables)
          return {} as R
        }

        if (!response || response.data === undefined || response.data === null) {
          throw new Error('No data presented in the GraphQL response')
        }

        if (dev) { console.info('RESPONSE:', response.data) }

        return response.data
      }
      case 'subscription': {
        throw new Error(
          'Subscription requests through SDK interface are not supported',
        )
      }
    }
  }

  return getSdk(requester)
}

export type Sdk = ReturnType<typeof getSdkApollo>