import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { CircularProgress } from '@material-ui/core'

interface Props {
  store?: any
}

@inject('store')
@observer
class MainLayout extends Component<Props> {
  // Create root store
  drawer: any

  render() {
    const { ready, authStore } = this.props.store
    const { isLoggedIn } = authStore

    if (!ready) {
      return (
        <>
          <CircularProgress color={'secondary'} className={'loadingSpinner'} />
          {styles}
        </>
      )
    }

    if (isLoggedIn) {
      return (
        <>{this.props.children}</>
      )
    }
    return <></>
  }
}

const styles = (
  <style>
    {`
      .loadingSpinner {
        position: absolute;
        top: calc(50vh - 20px);
        left: calc(50vw - 20px);
      }
    `}
  </style>
)

export default MainLayout
