import React from 'react'

import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core'

const ResponsiveDrawerItem = ({ ...props }: any) => {
  const { item, index, selected, disabled = false, onPress } = props
  const { icon, title } = item
  const onSectionPressed = () => { onPress(index) }
  const params = !disabled ? { onClick: onSectionPressed, button: true } : {}

  return (
    // @ts-ignore
    <ListItem key={title} selected={selected} {...params}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  )
}

export default ResponsiveDrawerItem
