import { useMemo } from 'react'
import RootStore from '../src/stores/RootStore'

export default function useViewModel<T>(VM: new (store: RootStore) => T, store: RootStore): T {
  return useMemo(() => new VM(store), [store])
}

interface Props {
  store?: RootStore
}

export function useViewModelWithProps<T>(VM: new (props: Props) => T, props: Props): T {
  return useMemo(() => new VM(props), [props.store])
}