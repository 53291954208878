import { Box, Button, DialogContent, Typography } from '@material-ui/core'
import { inject, observer } from 'mobx-react'
import React from 'react'
import RootStore from '../../src/stores/RootStore'
import { FormTextField } from '../shared'

interface Props {
  onEditField: (key: string, value: string) => void
  credentials: {
    emailAddress: string,
    password: string,
  },
  proceed: () => void
  logout: () => void
  store?: RootStore
}

const LoginStage = (props: Props) => {
  const { authStore: { isLoggedIn } } = props.store!
  const { onEditField, credentials, proceed, logout } = props

  return <>
    <DialogContent>
      <Typography variant={'h4'}>Sign in</Typography>

      <Box mt={2} />

      <Typography>Your session has expired, please login to continue</Typography>

      <Box mt={4} />

      <FormTextField
        label={'Email address'}
        property={'emailAddress'}
        value={credentials.emailAddress}
        onChange={onEditField}
      />

      <Box mt={1.5} />

      <FormTextField
        label={'Password'}
        property={'password'}
        type={'password'}
        value={credentials.password}
        onChange={onEditField}
      />

      <Box mt={4} />

      <Button variant='contained' color='primary' onClick={proceed} fullWidth>Login</Button>
      <Box mt={2} />
      {!!isLoggedIn && <Button variant='text' color='primary' onClick={logout} fullWidth>Logout</Button>}
    </DialogContent>
  </>
}

export default inject('store')(observer(LoginStage))