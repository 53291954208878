export interface FormattedError {
  code: string
  message: string
  queryName: string
  variables: string
  location: string
  userId: string
}

export function formatError(e: any, queryName: string, queryVariables: any, location: string, userId: string): FormattedError {
  const { code = '', message = '', variables = '' } = buildError(e, queryName, queryVariables)

  return {
    code,
    queryName,
    message,
    variables,
    location,
    userId,
  }
}

export function buildError(e: any, queryName: string, queryVariables: any) {
  const hasInvalidGraphError = !e.graphQLErrors || e.graphQLErrors.length === 0 || !e.graphQLErrors[0].extensions
  const isTokenExpired = JSON.stringify(e).includes('AUTH_TOKEN_EXPIRED')
  const stringifedVariables = JSON.stringify(queryVariables)

  if (hasInvalidGraphError && !isTokenExpired) {
    return buildServerError(e, queryName, stringifedVariables)
  } else if (isTokenExpired) {
    return buildExpirationError(e, queryName, stringifedVariables)
  } else {
    return buildGraphQLError(e, queryName, stringifedVariables)
  }
}

export function buildServerError(error: any, queryName: string, queryVariables: any) {
  const graphQLError = getGraphError(error)

  if (graphQLError) {
    return {
      error: 'Server Error',
      message: graphQLError.message,
      code: graphQLError.extensions.code,
      queryName,
      variables: queryVariables,
    }
  }

  const message = error.message ||
    (error.networkError && error.networkError.message) ||
    'An unknown error message occurred'

  return {
    error: 'Server Error',
    message,
    code: 'SERVER_CONNECTION_ERROR',
    queryName,
    variables: queryVariables,
  }
}

export function buildExpirationError(_e: any, queryName: string, queryVariables: any) {
  return {
    error: 'Token expired',
    message: 'Token expired',
    code: 'AUTH_TOKEN_EXPIRED',
    queryName,
    variables: queryVariables,
  }
}

export function buildGraphQLError(e: any, queryName: string, queryVariables: any) {
  const error = e.graphQLErrors[0]
  const message = e.graphQLErrors[0].message
  const code = e.graphQLErrors[0].extensions.code

  return {
    error,
    message,
    code,
    queryName,
    variables: queryVariables,
  }
}

export function getGraphError(error: any) {
  if (error?.graphQLErrors?.length > 0) {
    return error.graphQLErrors[0]
  }

  if (error?.networkError?.result?.errors) {
    return error.networkError.result.errors[0]
  }

  return false
}